body {
  margin: 0;
  font-family: 'Muli', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  /* font-family: 'Bitter', serif; */
  font-family: 'Muli', sans-serif;
  font-weight: bolder;
  /* background-color: bisque; */
  background-image:linear-gradient(to bottom right,#ffba69, bisque)
}

.search_menu {
  background-color: white;
}

.search_item {
  cursor: pointer;
  color:#212529;
}

.search_item a:hover {
  background-image:linear-gradient(to bottom right,#ffba69, bisque)

  /* background-color: bisque; */
}

.search_item:active {
  background-image:linear-gradient(to bottom right,bisque,#ffba69 )

  /* background-color: bisque; */
}

.custom_button {
  cursor: pointer;
}

.custom_button:active {
  color:#212529;
}

.custom_button:hover {
  /* background-color: bisque; */
}

.header_settings_button {
  cursor: pointer;
}

.main-content {
  padding: 0;
}

.content {
  padding: 1ch;
}

.priority-buttons {
  background-color: white;
  color:#212529;
}

.urgent_p {
  background-color: crimson;
  color: white;
}

.high_p {
  background-color: darkred;
  color: white;
}

.medium_p {
  background-color: orangered;
  color: white;
}

.low_p {
  background-color: green;
  color: white;
}

.expired_task {
  background-image:linear-gradient(to bottom right,indianred,rgb(253, 99, 43));
  /* background-color:  */
  color: white;
  border: 1px solid white;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.back_button {
  cursor: pointer;
}
.back_button:hover {
  text-decoration: underline;
}


.page-link {
  color: #212529;
}
.active {
  background-color:red
}

.page-item.active .page-link {
  background-color:null;
  background-image:linear-gradient(to bottom right,#ffba69, #f7a748);
  border-color: #ffba69;
  color: #212529;
  outline: none;
}
.page-item.active .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 167, 72,.25);
}
.page-item .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 167, 72,.25);
}

.long_words {
  -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.badgeW {
  width: 60px;
}

.badgeW2 {
  width: 80px;
}